import axios from 'axios';
import React, {useState, useEffect} from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom';
import swal  from 'sweetalert';

function EditCategory() {

   // const navigate = useNavigate();
    const params  = useParams();
    const [categoryInput, setCategory] = useState({
        slug:'',
        name:'',
        description:'',
        meta_title:'',
        meta_keyword:'',
        meta_description:'',
    });
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState([]); 
    const [picture, setPicture] = useState([]);


    const [allcheckbox, setCheckbox] = useState({
        status:'',
    });
    const handleImage = (e) =>
    {
        setPicture({image: e.target.files[0]})
    }

    useEffect(() => {
        const category_id = params.id;


        axios.get(`/api/edit-category/${category_id}`).then(res => {
            console.log(res)
            if(res.data.status === 200){
                setCategory(res.data.category);
                setCheckbox({status:res.data.category.status});
                setPicture({image:res.data.category.image});
                console.log(res.data.category.status)

            }else if(res.data.status === 404){
                swal('Error', res.data.message, 'error')
                navigate('/admin/view-category')
            }
            setLoading(false); 
        })
    }, [params.id, navigate])
    

    const handleInput = (e) => {
        e.persist();

        setCategory({...categoryInput, [e.target.name]: e.target.value });
    }
    const handleCheckbox = (e) => {
        e.persist();

        setCheckbox({...allcheckbox, [e.target.name]: e.target.checked});
    }
    const updateCategory = (e) => {
        e.preventDefault();

        const formData = new FormData();

        formData.append('image', picture.image);

        formData.append('slug', categoryInput.slug);
        formData.append('name', categoryInput.name);
        formData.append('description', categoryInput.description);
        formData.append('meta_title', categoryInput.meta_title);
        formData.append('meta_keyword', categoryInput.meta_keyword);
        formData.append('meta_description', categoryInput.meta_description);
        formData.append('status', allcheckbox.status ? 1 : 0);

        const category_id = params.id;
        axios.post(`/api/update-category/${category_id}`, formData).then(res => {
            if(res.data.status === 200)
            {
                swal("Success", res.data.message, "success");
                setError([]);
            }
            else if(res.data.status === 422)
            {
                swal("All fields are mandatory", "", 'error');
                setError(res.data.validation_errors);      
            }
            else if(res.data.status === 404)
            {
                swal('Error', res.data.message, 'error');
                navigate('/admin/view-category');
            }
        })
    }
    if(loading){
        return <h4>Loading Category Details....</h4>
      }

  return (
    <div className="container-fluid px-4">
    <h4 className="mt-4">Edit Category
        <Link to="/admin/view-category" className="btn btn-primary btn-sm float-end">BACK</Link>
    </h4>
    {/* {display_errors.map( (item, i) => {
        return(<p style={{color: 'red'}} key={i} className="mb-1" >{item}</p>);
    })} */}

    <form onSubmit={updateCategory} encType="multipart/form-data">
    <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
                <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Home</button>
            </li>
            <li className="nav-item" role="presentation">
                <button className="nav-link" id="seo-tags-tab" data-bs-toggle="tab" data-bs-target="#seo-tags" type="button" role="tab" aria-controls="seo-tags" aria-selected="false">SEO Tags</button>
            </li>
           
        </ul>
        <div className="tab-content" id="myTabContent">
            <div className="tab-pane card-body border fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div className="form-group mb-3">
                    <label>Slug</label>
                    <input type="text" name="slug" onChange={handleInput} value={categoryInput.slug} className="form-control" />
                    <small className="text-danger">{error.slug}</small>
                </div>
                <div className="form-group mb-3">
                    <label>Name</label>
                    <input type="text" name="name" onChange={handleInput} value={categoryInput.name} className="form-control" />
                    <small className="text-danger">{error.name}</small> 
                </div>
                <div className="form-group mb-3">
                    <label>Description</label>
                    <textarea name="description" onChange={handleInput} value={categoryInput.description} className="form-control"></textarea>
                </div>
                <div className="form-group mb-3">
                    
                    <input type="checkbox" name="status" onChange={handleCheckbox} defaultChecked={allcheckbox.status === 1 ? true:false}  /> Status 0=shown/1=hidden
                </div>
            </div>
            <div className="tab-pane card-body border fade" id="seo-tags" role="tabpanel" aria-labelledby="seo-tags-tab">
                <div className="form-group mb-3">
                        <label>Meta Title</label>
                        <input type="text" name="meta_title" onChange={handleInput} value={categoryInput.meta_title} className="form-control" />
                        <small className="text-danger">{error.meta_title}</small>
                </div>
                <div className="form-group mb-3">
                        <label>Meta Keywords</label>
                        <textarea name="meta_keyword" onChange={handleInput} value={categoryInput.meta_keyword} className="form-control" ></textarea>
                </div>
                <div className="form-group mb-3">
                        <label>Meta Description</label>
                        <textarea name="meta_description" onChange={handleInput} value={categoryInput.meta_description} className="form-control" ></textarea>
                </div>
                <div className="col-md-8 form-group mb-3">
                        <label>Image</label>
                        <input type="file" name="image" onChange={handleImage} className="form-control" />
                        <img src={`http://p3portfolio.com/laraecomm/public/${picture.image}`} width="50px" alt={categoryInput.name} />

                </div>
            </div>              
        </div>
        <button type="submit" className="btn btn-primary px-4 float-end">Update</button>
        </form>
</div>
  )
}

export default EditCategory;
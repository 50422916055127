import axios from 'axios';
import React, {useState, useEffect} from 'react'
import {useNavigate, Link} from 'react-router-dom';
import swal from 'sweetalert';
import NumberFormat from 'react-number-format';


function Cart() {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [cart, setCart] = useState([]);
    var totalCartPrice = 0;

    if(!localStorage.getItem('auth_token')){
        navigate('/');
        swal("Warning", 'Login to go to Cart page', 'error');
    }
    useEffect( () =>{

        let isMounted = true;

        axios.get(`/api/cart`).then(res => {
            if(isMounted){
                if(res.data.status === 200){
                    setCart(res.data.cart);
                    setLoading(false);

                }
                else if(res.data.status === 401){
                    swal('Error', res.data.message, 'error');
                    navigate('/public/collections');
                }
            }
        })

    }, [navigate])
    
    const handleDecrement = (cart_id) => {
       
        setCart(cart =>
            cart.map( (item) => 
                cart_id === item.id ? {...item, product_qty : item.product_qty - (item.product_qty > 1 ? 1:0 )} : item
            ))
        updateCartQuantity(cart_id,'dec');
    }

    const handleIncrement = (cart_id) => {
       
        setCart(cart =>
            cart.map( (item) => 
                cart_id === item.id ? {...item, product_qty : item.product_qty + (item.product_qty < 10 ? 1:0 )} : item
            ))
        updateCartQuantity(cart_id,'inc');
    }

    function updateCartQuantity(cart_id,scope)
    {
        axios.put(`/api/cart-updatequantity/${cart_id}/${scope}`).then( res => {
            if(res.data.status === 200){
                //swal('Success', res.data.message, 'success');
            }
        })
    }

    const deleteCartItem = (e, cart_id) => {
        e.preventDefault();
    
        const thisClicked = e.currentTarget;
        thisClicked.innerText = 'Removing...'; 
    
        axios.delete(`/api/delete-cartitem/${cart_id}`).then(res => {
          if(res.data.status === 200){
            swal('Success', res.data.message, 'success');
            thisClicked.closest("tr").remove();
            navigate('/public/cart')
          }
          else if(res.data.status === 404)
          {
            swal("Error", res.data.message, "error");   
            thisClicked.innerText = 'Remove';
            navigate('/public/cart')
    
          }
          else if(res.data.status === 401){
            swal("Warning", res.data.message, "warning");   
            thisClicked.innerText = 'Remove';
            navigate('/public/cart')
          }
        })
      }

    if(loading){
        return <h4>Loading Cart items...</h4>
    }

    var cart_HTML = '';

    if(cart.length > 0){
        cart_HTML = 
        <div>
            <div className="table-responsive">
                        <table className="table table-responsive">
                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Product</th>
                                    <th className="text-center">Price</th>
                                    <th className="text-center">Quantity</th>
                                    <th className="text-center">Total Price</th>
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                { cart.map( (item, index) => {

                                    totalCartPrice += item.product.selling_price * item.product_qty;

                                    return (

                                <tr key={index}>
                                    <td width="10%">
                                        <img src={`http://copart24.com/public/${item.product.image}`} alt={item.product.name} vidth='50px' height="50px" />
                                    </td>
                                    <td>{item.product.name}</td>
                                    <td width="15%" className="text-center"><NumberFormat value={item.product.selling_price} displayType={"text"} thousandSeparator={true} prefix={'₦'} /></td>
                                    <td width="15%">
                                        <div className="input-group">
                                            <button type="button" onClick={ () => handleDecrement(item.id)} className="input-group-text">-</button>
                                            <div className="form-control text-center">{item.product_qty}</div>
                                            <button type="button" onClick={ () => handleIncrement(item.id)} className="input-group-text">+</button>
                                        </div>
                                    </td>
                                    <td width="15%" className="text-center"><NumberFormat value={item.product.selling_price * item.product_qty} displayType={"text"} thousandSeparator={true} prefix={'₦'} /></td>
                                    <td width="10%">
                                        <button type="button" onClick={ (e) => deleteCartItem(e, item.id)} className="btn btn-danger btn-sm">Remove</button>
                                    </td>
                                </tr>

                                )
                                })}
                            </tbody>
                        </table>
            </div>

            <div className="row">
                        <div className="col-md-8"></div>
                                <div className="col-md-4">
                                        <div className="card card-body mt-3">
                                            <h6>Sub Total:
                                                <span className="float-end"><NumberFormat value={totalCartPrice} displayType={"text"} thousandSeparator={true} prefix={'₦'} /></span>
                                            </h6>
                                            <h6>Grand Total:

                                                <span className="float-end"><NumberFormat value={totalCartPrice} displayType={"text"} thousandSeparator={true} prefix={'₦'} /></span>
                                            </h6>
                                            <hr />
                                            <Link to="/public/checkout" className="btn btn-primary btn-sm">Checkout</Link>
                                        </div>
                                </div>
            </div>
        </div>
       
    }
    else
    {
        cart_HTML = 
        <div className="table-responsive">
        <table className="table table-responsive">
            <div className="card card-body py-5 text-center shadow-sm">
                <h4>Your Shopping Cart is Empty</h4>
            </div>
        </table>
        </div>
    }
 

  return (
    <div>
        <div className="py-3" style={{backgroundColor: '#FFFDD0'}}>
            <div className="container">
                <h6>Home / Cart</h6>
            </div>
        </div>
        <div className="py-4">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                       
                            {cart_HTML}
                    </div>
                 
                    </div>
                </div>
            </div>

        

    </div>        
  )
}

export default Cart;
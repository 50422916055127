import axios from 'axios';
import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
//import swal from 'sweetalert';

function ViewProduct() {

    //const navigate = useNavigate();
    const [viewProduct, setProduct] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        document.title = 'View Product';
        axios.get(`/api/view-product`).then(res => {
            if(res.data.status === 200)
            {
                //console.log(res.data.products);
                setProduct(res.data.products);
                setLoading(false);
            }
        });
    }, []);


  /* const deleteProduct = (e, id) => {
    e.preventDefault();

    const thisClicked = e.currentTarget;
    thisClicked.innerText = 'Deleting...'; 

    axios.delete(`/api/delete-product/${id}`).then(res => {
      if(res.data.status === 200){
        swal('Success', res.data.message, 'success');
        thisClicked.closest("tr").remove();
        navigate('/admin/view-product')
      }
      else if(res.data.status === 404)
      {
        swal("Error", res.data.product_error, "error");   
        thisClicked.innerText = 'Delete';
        navigate('/admin/view-product')

      }
    })
  } */

    var display_productdata = '';
    if(loading)
    {
        return <h4>View Products Loading...</h4>
    }
    else
    {
       var ProdStatus = ''; 
       display_productdata = viewProduct.map( (item) => {
           if(item.status === 0)
           {
               ProdStatus = 'Shown';
           }else if(item.status === 1)
           {
               ProdStatus = 'Hidden';
           }
            return(
                <tr key={item.id}> 
                    <td>{item.id}</td>
                    <td>{item.category.name}</td>
                    <td>{item.name}</td>
                    <td>{item.selling_price}</td>
                        <td><img src={`http://copart24.com/public/${item.image}`} alt="product pix" width="50px"></img></td>
                    <td><Link to={`/admin/edit-product/${item.id}`} className="btn btn-success btn-sm">Edit</Link></td>
                    {/* <td><button type="button" onClick={ (e) => deleteProduct(e, item.id)} className="btn btn-danger btn-sm">Delete</button></td> */}
                    <td>{ProdStatus}</td>
                </tr>  
            );
        });
    }

    
  return (
    <div className="card px-4 mt-3">
        <div className="card-header">
            <h4>View Product
                <Link to="/admin/add-product" className="btn btn-primary btn-sm float-end">Add Product</Link>
            </h4>
        </div>
        <div className="card-body">
            <div className="table-responsive">
                <table className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Category Name</th>
                            <th>Product Name</th>
                            <th>Selling Price</th>
                            <th>Image</th>
                            <th>Edit</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {display_productdata}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  )
}

export default ViewProduct;
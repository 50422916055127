import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import swal from 'sweetalert';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import ReactDOM  from 'react-dom';


function Checkout() {

    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [cart, setCart] = useState([]);
    var totalCartPrice = 0;
    const [error, setError] = useState([]);

    const [checkoutinput, setCheckoutinput] = useState({
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        address: '',
        city: '',
        state: '',
        zipcode: '',

    });

    const handleInput = (e) => {
        e.persist();
        setCheckoutinput({...checkoutinput, [e.target.name]: e.target.value});
    }
    const orderinfo_data = {
        firstname: checkoutinput.firstname,
        lastname: checkoutinput.lastname,
        phone: checkoutinput.phone,
        email: checkoutinput.email,
        address: checkoutinput.address,
        city: checkoutinput.city,
        state: checkoutinput.state,
        zipcode: checkoutinput.zipcode,
        payment_mode: 'Paypal',
        payment_id: '',
    }
//PAYPAY CODES
    const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });

    const createOrder = (data, actions) => {
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                //value: totalCartPrice,
                value: '0.1',
              },
            },
          ],
        });
      };
      const onApprove = (data, actions) => {
        //return actions.order.capture();
        return actions.order.capture().then(function(details){
            orderinfo_data.payment_id = details.id;

            axios.post(`/api/place-order`, orderinfo_data).then(res => {
                if(res.data.status === 200)
                {
                    console.log('this is it')
                    swal("Order placed successfully", res.data.message, 'success');
                    setError([]);
                    navigate('/public/thank-you');
                }
                else if(res.data.status === 422)
                {
                    swal('All Fields are Mandatory', '', 'error')
                    setError(res.data.validation_errors)
                }
                else if(res.data.status === 401)
                {
                    swal('Warning', res.data.message, 'error');
    
                }
            });
        });
      };
//End paypal codes

    const submitOrder = (e, payment_mode) => {
        e.preventDefault();    //to prevent page reload

        const data = {
            firstname: checkoutinput.firstname,
            lastname: checkoutinput.lastname,
            phone: checkoutinput.phone,
            email: checkoutinput.email,
            address: checkoutinput.address,
            city: checkoutinput.city,
            state: checkoutinput.state,
            zipcode: checkoutinput.zipcode,
            payment_mode: payment_mode,
            payment_id: '',
        }
switch (payment_mode) {
    case "COD":
        axios.post(`/api/place-order`, data).then(res => {
            if(res.data.status === 200)
            {
                console.log('this is it')
                swal("Order placed successfully", res.data.message, 'success');
                setError([]);
                navigate('/public/thank-you');
            }
            else if(res.data.status === 422)
            {
                swal('All Fields are Mandatory', '', 'error')
                setError(res.data.validation_errors)
            }
            else if(res.data.status === 401)
            {
                swal('Warning', res.data.message, 'error');

            }
        });
        break;
    case "razorpay":
        axios.post(`/api/validate-order`, data).then(res => {
            if(res.data.status === 200)
            {
                setError([]);

                var options = {
                    "key": "rzp_test_VEyrSziKJj71v3", // Enter the Key ID generated from the Dashboard
                    //"amount": (totalCartPrice * 100), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                    "amount": (1 * 100), //use this to test with indian currency as it is an indian company
                    //"currency": "NGN",
                    "name": "P3 Portfolio",
                    "description": "Thank you for purchasing with P3-Portfolio",
                    "image": "https://example.com/your_logo",
                    "handler": function (response){
                        alert(response.razorpay_payment_id);
                        data.payment_id = response.razorpay_payment_id

                        axios.post(`/api/place-order`, data).then(res => {
                            if(res.data.status === 200)
                            {
                                console.log('this is it')
                                swal("Order placed successfully", res.data.message, 'success');
                                setError([]);
                                navigate('/public/thank-you');
                            }
                           
                        });
                    },
                    "prefill": {
                        "name": data.firstname + data.lastname,
                        "email": data.email,
                        "contact": data.phone,
                    },
                    "theme": {
                        "color": "#3399cc"
                    }
                };
                var rzp = new window.Razorpay(options); 
                    rzp.open();
            }
            else if(res.data.status === 422)
            {
                swal('All Fields are Mandatory', '', 'error')
                setError(res.data.validation_errors)
            }
        })    

        break;
    case "payonline":
        axios.post(`/api/validate-order`, data).then(res => {
            if(res.data.status === 200)
            {
                setError([]);
                var myModal = new window.bootstrap.Modal(document.getElementById('payOnlineModal'))
                myModal.show();
            }
            else if(res.data.status === 422)
            {
                swal('All Fields are Mandatory', '', 'error')
                setError(res.data.validation_errors)
            }
        });  
        break;

    default:
        break;
}

        
    }

    if(!localStorage.getItem('auth_token')){
        navigate('/');
        swal("Warning", 'Login to go to Cart page', 'error');
    }

    useEffect( () =>{

        let isMounted = true;

        axios.get(`/api/cart`).then(res => {
            if(isMounted){
                if(res.data.status === 200){
                    setCart(res.data.cart);
                    setLoading(false);

                }
                else if(res.data.status === 401){
                    swal('Error', res.data.message, 'error');
                    navigate('/public/collections');
                }
            }
        })

        return () => {
            isMounted = false;
        }
    }, [navigate])
    
    
    if(loading)
    {
        return <h4>Loading Checkout...</h4>
    }

    var checkout_HTML = '';

    if(cart.length > 0){
        checkout_HTML = 
        <div>
            <div className="row">
                    <div className="col-md-7">
                        <div className="card">
                            <div className="card-header">
                                <h4>Basic Information</h4>
                            </div>
                            <div className="card-body">

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label>First Name</label>
                                            <input type="text" onChange={handleInput} value={checkoutinput.firstname} name="firstname" className="form-control" />
                                            <small className="text-danger">{error.firstname}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label>Last Name</label>
                                            <input type="text" onChange={handleInput} value={checkoutinput.lastname} name="lastname" className="form-control" />
                                            <small className="text-danger">{error.lastname}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label>Phone Number</label>
                                            <input type="text" onChange={handleInput} value={checkoutinput.phone} name="phone" className="form-control" />
                                            <small className="text-danger">{error.phone}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label>Email Address</label>
                                            <input type="text" onChange={handleInput} value={checkoutinput.email} name="email" className="form-control" />
                                            <small className="text-danger">{error.email}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label>Full Address</label>
                                            <textarea name="address"  onChange={handleInput} value={checkoutinput.address} rows="3" className="form-control"></textarea>
                                            <small className="text-danger">{error.address}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group mb-3">
                                            <label>City</label>
                                            <input type="text" onChange={handleInput} value={checkoutinput.city} name="city" className="form-control" />
                                            <small className="text-danger">{error.city}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group mb-3">
                                            <label>State</label>
                                            <input type="text" onChange={handleInput} value={checkoutinput.state} name="state" className="form-control" />
                                            <small className="text-danger">{error.state}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group mb-3">
                                            <label>Zip Code</label>
                                            <input type="text" onChange={handleInput} value={checkoutinput.zipcode} name="zipcode" className="form-control" />
                                            <small className="text-danger">{error.zipcode}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group text-end">
                                            <button type="button" className="btn btn-primary mx-1" onClick={ (e) => submitOrder(e, "COD")}>Place Order</button>                                        
                                            <button type="button" className="btn btn-primary mx-1" onClick={ (e) => submitOrder(e, "razorpay")}>Razor Pay</button>                                        
                                            <button type="button" className="btn btn-warning mx-1" onClick={ (e) => submitOrder(e, "payonline")}>Pay Online</button>                                        
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <table className="table table-stripped table-bordered">
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Price</th>
                                    <th>Qty</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                            { cart.map( (item, index) => {

                                totalCartPrice += item.product.selling_price * item.product_qty;

                                return (

                                    <tr key={index}>
                                        <td>{item.product.name}</td>
                                        <td>{item.product.selling_price}</td>
                                        <td>{item.product_qty}</td>
                                        <td>
                                        <NumberFormat value={item.product.selling_price * item.product_qty} displayType={"text"} thousandSeparator={true} prefix={'₦'} />
                                        </td>
                                    </tr>
                                  )
                                })}
                                <tr>
                                    <td colSpan="2" className="text-end fw-bold">Grand Total</td>
                                    <td></td>
                                    <td colSpan="2" className="text-end fw-bold">
                                    <NumberFormat value={totalCartPrice} displayType={"text"} thousandSeparator={true} prefix={'₦'} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
            </div>
        </div>
    }
    else
    {
        checkout_HTML = 
        <div className="table-responsive">
        <table className="table table-responsive">
            <div className="card card-body py-5 text-center shadow-sm">
                <h4>Your Shopping Cart is Empty</h4>
            </div>
        </table>
        </div>
    }

  return (
    <div>
         {/* Modal */}
        <div className="modal fade" id="payOnlineModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Online Payment</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <hr />
                    <PayPalButton
                    createOrder={(data, actions) => createOrder(data, actions)}
                    onApprove={(data, actions) => onApprove(data, actions)}
                    />
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary">Save changes</button>
                </div>
                </div>
            </div>
        </div>
        {/* Modal End */}

        <div className="py-3 bg-warning">
            <div className="container">
                <h6>Home / Checkout</h6>
            </div>
        </div>

        <div className="py-4">
            <div className="container">
               {checkout_HTML}
            </div>
        </div>
    </div>
  )
}

export default Checkout;
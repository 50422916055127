import axios from 'axios';
import React, {useState, useEffect} from 'react'
import NumberFormat from 'react-number-format';
import { Link, useNavigate, useParams } from 'react-router-dom';
import swal from 'sweetalert';

function ProductDetails() {

    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [product, setProduct] = useState([]);
    const [quantity, setQuantity] = useState(1);

    useEffect(() => {
      
    let isMounted = true;
    const category_slug = params.category;
    const product_slug = params.product;
    axios.get(`/api/view-product/${category_slug}/${product_slug}`).then(res => {
        if(isMounted)
        {
            if(res.data.status === 200){
                setProduct(res.data.product);
                console.log(res.data.product)
                setLoading(false);
            }
            else if(res.data.status === 404)
            {
                navigate('public/collections');
                swal('Error', res.data.message, 'error');
            }
        }
      
    });
    return () => {
        isMounted(false);
    };
      }, [params.category, params.product, navigate])
    
      //Quantity Increment and Decrement in hooks start
      const handleDecrement = () => {
          if(quantity > 1){
            setQuantity(prevCount => prevCount - 1);
          }
      }

      const handleIncrement = () => {
          if(quantity < 10){
            setQuantity(prevCount => prevCount + 1);
          }
      }
       //Quantity Increment and Decrement in hooks end
      //Add to cart
      const submitAddtocart = (e) => {
          e.preventDefault();

          const data = {
              product_id : product.id,
              product_qty : quantity,
          }

          axios.post(`/api/add-to-cart`, data).then(res => {
            if(res.data.status === 201){
                swal('Success', res.data.message, 'success')
            }
            else if(res.data.status === 409){
                //Already added to cart
                swal('Success', res.data.message, 'warning');
            }
            else if(res.data.status === 401){
                swal('Unauthorized', res.data.message, 'error');
            }
            else if(res.data.status === 404){
                swal('Warning', res.data.message, 'warning');
            }
          })
      }

if(loading)
{
    return <h4>Loading Product Details...</h4>
}else
{
    var available_stock = '';
    if(product.qty > 0)
    {
        available_stock = <div>
        <label className="btn-sm btn-success px-4 mt-2">In stock</label>
    
        <div className="row">
            <div className="col-md-3 mt-3">
                <div className="input-group">
                    <button type="button" onClick={handleDecrement} className="input-group-text">-</button>
                    <div className="form-control text-center">{quantity}</div>
                    <button type="button" onClick={handleIncrement} className="input-group-text">+</button>
                </div>
            </div>
            <div className="col-md-3 mt-3">
                <button type="button" className="btn btn-primary w-100" onClick={submitAddtocart}>Add to Cart</button>
            </div>
        </div>
    </div>
    }
    else 
    {
        available_stock = <div>
            <label className="btn-sm btn-danger px-4 mt-2">Out of stock</label>
        </div>
    }
    
   
}
  return (
      <div>
        <div className="py-3" style={{backgroundColor: '#FFFDD0'}}>
            <div className="container">
                <h6><Link to="/public/collections" style={{textDecoration: 'none'}}>Collections</Link> / {product.category.name} / {product.name}</h6>
            </div>
        </div>
        <div className="py-3">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 border-end">
                        <img src={`http://copart24.com/public/${product.image}`} alt="product name" className="w-100" />
                    </div>
                    <div className="col-md-8">
                        <h4>
                            {product.name}
                            <span className="float-end badge btn-sm btn-danger badge-pil">{product.brand}</span>
                        </h4>
                        <p>{product.description}</p>
                        <h4 className="mb-1">
                        <NumberFormat value={product.selling_price} displayType={"text"} thousandSeparator={true} prefix={'₦'} />
                            <s className="ms-2"> <NumberFormat value={product.original_price} displayType={"text"} thousandSeparator={true} prefix={'₦'}  /></s>
                        </h4>
                        {available_stock}
                        <button type="button" className="btn btn-danger mt-3">Add to Wishlist</button>
                    </div>
                    
                </div>
            </div>
        </div>
      </div>
   
    
  )
}

export default ProductDetails;
import React from 'react';
import Navbar from '../../layouts/frontend/Navbar';

function Home(){
    return(
        <div>
            <Navbar />
            <div className="container">
                <div className="card">
                    <div className="card-header">
                       <h4> Laravel and React JS E-Commerce Web Application </h4>
                    </div>
                    <div className="card-body">
                        <div className="card">
                        <div className="card-header">
                            <h4>Features </h4>
                        </div>
                        <div className="card-body">
                            <ul>
                            <li>Collections - showing a list of product categories </li>
                            <li>Products management </li>
                            <li>Product details page</li>
                            <li>Cart management </li>
                            <li>Checkout </li>
                            <li>Cash on Delivery management </li>
                            <li>Online payment with Razorpay </li>
                            <li>Online payment with Paypal </li>
                            <li>Admin and Users management </li>
                            </ul>
                        </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        
    );
}

export default Home;
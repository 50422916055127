import React from 'react';
import {Outlet} from 'react-router-dom';
import '../../assets/admin/css/styles.css';
import '../../assets/admin/js/scripts.js';



import Navbar from '../../layouts/frontend/Navbar';



const FrontendLayout = ()=>{
    return (
        <div>
            <Navbar/>

            <div>
                
                <Outlet/>
                

            </div>

        </div>

    );
}

export default FrontendLayout;
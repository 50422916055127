import React, { useState, useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';

function AdminPrivateRoute(){

   const navigate = useNavigate();
   const [Authenticated, setAuthenticated] =useState(false);
   const [loading, setLoading] = useState(true);

   useEffect(() => {
     axios.get('/api/checkingAuthenticated').then(res => {
      if(res.data.status === 200)
      {
         setAuthenticated(true);
      }
      else if(res.data.status === 403)  //Access Denied
      {
         swal('Forbidden', res.data.message, 'warning');
         console.log(res.data.status);

      } 
      else if(res.data.status === 404)  //Page not found
      {
         swal('404 Error', 'URL/Page not found', 'warning');
      }
    //console.log(res);
    
      setLoading(false);
     });

   
     return () => {
       setAuthenticated(false);
     };
     
   }, []);

axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err){
   if(err.response.status === 401)
   {
      swal('Unauthorized', err.response.data.message, "warning" );
      navigate('/');
   }
 
   return Promise.reject(err);
});

if(loading){
   return <h1>Loading...</h1>;
}   


//isLogged = Authenticated;

   return Authenticated ? <Outlet />: <Navigate to="/" />
}

export default AdminPrivateRoute;
import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom';
import axios from 'axios';

//import './style.css';


function Collections() {

    const [category, setCategory] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect( () => {

        let isMounted = true;

        axios.get(`/api/getcategory`).then(res => {
            if(isMounted)
            {
                if(res.data.status === 200){
                    //console.log(res.data.category)
                    setCategory(res.data.category);
                    setLoading(false);
                }
            }
          
        });
        return () => {
            isMounted = false;
        }
    }, []);

    if(loading)
    {
        return <h4>Loading Categories...</h4>
    }
    else
    {
        var showCategorylist = '';
        showCategorylist = category.map( (item, index) => {
            return(

                <div className="col-md-3" key={index}>
                    <div className="card align-items-center shadow p-3 mb-5 bg-body rounded">
                       
                        <Link to={`/public/collections/${item.slug}`} >
                            <img src={`http://copart24.com/public/${item.image}`} width="100px" height="100px" alt={item.name} />
                        </Link>
                        <div className="card-body align-content-center">
                            <Link to={`/public/collections/${item.slug}`} >
                            <h6 style={{textAlign: 'center'}}>{item.name}</h6>
                            </Link>
                        </div>

                    </div>
                </div>
            );
        })
    }
    

  return (
    <div>
        <div className="py-3" style={{backgroundColor: '#FFFDD0'}}>
            <div className="container">
                <h6>Category Page</h6>
            </div>
        </div>
        <div className="py-3">
            <div className="container">
                <div className="row">
                    {showCategorylist}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Collections;
import axios from 'axios';
import React, {useState, useEffect} from 'react'
import NumberFormat from 'react-number-format';
import {useNavigate, useParams, Link} from 'react-router-dom';
import swal from 'sweetalert';

function ViewProducts() {

    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [product, setProduct] = useState([]);
    const [category, setCategory] = useState([]);

    const productCount = product.length;

  useEffect(() => {
    let isMounted = true; 

    const product_slug = params.slug;
    axios.get(`/api/fetch-products/${product_slug}`).then(res => {
        if(isMounted){
            if(res.data.status === 200){
                setProduct(res.data.product_data.product);
                setCategory(res.data.product_data.category);
                console.log(res.data.product_data);
                setLoading(false);
            }
            else if(res.data.status === 400){
                swal('Error', res.data.message, 'error')
                navigate('/public/collections');
            }
            else if(res.data.status === 404){
                swal('Warning', res.data.message, 'error')
                navigate('/public/collections');
            }
        }
    })
  
    return () => {
        isMounted = false;
    }
  }, [navigate, params.slug]);

  if(loading)
  {
    return <h4>Loading Products...</h4>
  }
  else
  {
    var showProductList = '';

    if(productCount)
    {
        showProductList = product.map( (item,index) => {
            return (<div className="col-md-3" key={index}>
                <div className="card">
                    <Link to={`/public/collections/${item.category.slug}/${item.slug}`}>
                    <img src={`http://copart24.com/public/${item.image}`} alt={item.name} className="w-100" />
                    </Link>
                    <div className="card-body mx-auto">
                        <Link to={`/public/collections/${item.category.slug}/${item.slug}`}>
                            {item.name}
                        </Link>
                        <p style={{textAlign: 'center'}}><NumberFormat value={item.selling_price} displayType={"text"} thousandSeparator={true} prefix={'₦'} /></p>
                    </div>
             </div>
            </div>);
        })
    }
    else
    {
        showProductList = 
        <div className="col-md-12">
            <h4> No Product Available for {category.name}</h4>
        </div>
    }
    
  }
  

  return (
    <div>
    <div className="py-3" style={{backgroundColor: '#FFFDD0'}}>
        <div className="container">
            <h6><Link to="/public/collections" style={{textDecoration: 'none'}}>Collections</Link> / {category.name}</h6>
        </div>
    </div>
    <div className="py-3">
        <div className="container">
            <div className="row">
                {showProductList}
            </div>
        </div>
    </div>
</div>
  )
}

export default ViewProducts;
import React, {useState, useEffect} from 'react'
import axios from 'axios';
import {Link} from 'react-router-dom';


function Vieworders() {

    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    

    useEffect(() => {
        let isMounted = true;

        document.title = 'View Orders';
        axios.get(`/api/view-orders`).then(res => {
            if(isMounted){
                if(res.data.status === 200)
                {
                    //console.log(res.data.products);
                    setOrders(res.data.orders);
                    setLoading(false);
                }
            }
           
        });
        return () => {
            isMounted = false;
        }
    }, []);

    var display_orderdata = '';
    if(loading)
    {
        return <h4>Loading Orders...</h4>
    }
    else
    {
       //var ProdStatus = ''; 
       display_orderdata = orders.map( (item) => {
         
            return(
                <tr key={item.id}> 
                    <td>{item.id}</td>
                    <td>{item.tracking_no}</td>
                    <td>{item.phone}</td>
                    <td>{item.email}</td>
                    <td><Link to={`/admin/view-order/${item.id}`} className="btn btn-success btn-sm">View</Link></td>
                    </tr>  
            );
        });
    }
    return (
        <div className="card px-4 mt-3">
            <div className="card-header">
                <h4>View Order
                </h4>
            </div>
            <div className="card-body">
                <div className="table-responsive">
                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Tracking No.</th>
                                <th>Phone</th>
                                <th>Email</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {display_orderdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
      )
    }
    
export default Vieworders;
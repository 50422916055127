import React from 'react';
import { MobileView } from "react-device-detect";

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import MasterLayout from './layouts/admin/MasterLayout';
import Dashboard from './components/admin/Dashboard';
import Profile from './components/admin/Profile';
import Home from './components/frontend/home';
import Login from './components/frontend/auth/Login';
import Register from './components/frontend/auth/Register';
import AdminPrivateRoute from './AdminPrivateRoute';

import axios from 'axios';
import Category from './components/admin/category/Category';
import ViewCategory from './components/admin/category/ViewCategory';
import EditCategory from './components/admin/category/EditCategory';
import AddProduct from './components/admin/products/AddProduct';
import ViewProduct from './components/admin/products/ViewProduct';
import EditProduct from './components/admin/products/EditProduct';
import About from './components/frontend/About';
import Contact from './components/frontend/Contact';
import PublicRoute from './PublicRoute';
import Collections from './components/frontend/collections/Collections';
import FrontendLayout from './layouts/frontend/FrontendLayout';
import ViewProducts from './components/frontend/collections/ViewProducts';
import ProductDetails from './components/frontend/collections/ProductDetails';
import Cart from './components/frontend/Cart';
import Checkout from './components/frontend/Checkout';
import Vieworders from './components/admin/orders/Vieworders';




axios.defaults.baseURL = "http://copart24.com/";
axios.defaults.headers.post['Content_Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';

axios.defaults.withCredentials = true;

axios.interceptors.request.use(function (config){
  const token = localStorage.getItem('auth_token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});



function App() {

  var appinfo = '';
  appinfo =   <div className="App">
  <Router>
   
    <Routes>
        <Route exact path="/" element={<Home />}/>
        
       <Route element={<PublicRoute />} >
          <Route path="/public" element={<FrontendLayout />} /> 
          <Route path='/public/about' element={<About />} />
          <Route path='/public/contact' element={<Contact />} /> 
          <Route path='/public/collections' element={<Collections />}  />
          <Route path='/public/collections/:slug' element={<ViewProducts />} />
          <Route path='/public/collections/:category/:product' element={<ProductDetails />} />
          <Route path='/public/cart' element={<Cart />} />
          <Route path="/public/checkout" element={<Checkout />} />
       </Route>
        
               
        <Route path="/login" element={localStorage.getItem('auth_token') ? <Navigate to="/" /> : <Login />} />
        <Route path="/register" element={localStorage.getItem('auth_token') ? <Navigate to="/" /> : <Register />} />
      <Route element={<AdminPrivateRoute />} >
        <Route path="/admin" element={<MasterLayout />} > 
        <Route path='/admin/view-category' element={<ViewCategory />} />
        <Route path='/admin/add-category' element={<Category />} />
        <Route path='/admin/edit-category/:id' element={<EditCategory />} />
        <Route path='/admin/add-product' element={<AddProduct />} />
        <Route path='/admin/view-product' element={<ViewProduct /> } />
        <Route path='/admin/edit-product/:id' element={<EditProduct />} />
        <Route path='/admin/view-orders/' element={<Vieworders />} /> 
        <Route path='/admin/profile' element={<Profile />} />
        <Route path='/admin/dashboard' element={<Dashboard />} />
      </Route> 
      </Route>
    </Routes>
  </Router>
</div>
  let type = new MobileView(window.navigator.userAgent)
  if(type){
    return (
      <div>
      {appinfo}
      </div>
    );
  }


}

export default App;
